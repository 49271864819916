<template>
    <DrawerTemplate
        v-model="visible"
        :title="requestData.name"
        :width="widthDrawer"
        classList="group_drawer"
        :class="isMobile && 'group_drawer_mobile'"
        destroyOnClose
        :afterVisibleChange="afterVisibleChange"
        @close="visible = false">
        <template slot="body">
            <a-skeleton 
                v-if="loading"  
                class="ml-2 mt-4" 
                active 
                avatar 
                :paragraph="{ rows: 4 }" 
                :loading="loading"/>
            <template v-if="!loading">
                <div class="group_header flex items-center justify-between">
                    <div class="group_info flex items-center truncate">
                        <div>
                            <a-avatar 
                                icon="fi-rr-users-alt"
                                flaticon
                                :size="35" 
                                :src="workgroupLogoPath" />
                        </div>
                        <h5 class="text-xl ml-3 truncate">
                            {{requestData.name}}
                        </h5>
                    </div>
                    <div class="group_buttons flex items-center pl-3">
                        <component 
                            v-if="isMobile"
                            :is="burgerWidget"
                            :requestData="requestData"
                            :is_project="is_project"
                            :isFounder="isFounder"
                            :actions="actions"
                            :isStudent="isStudent"
                            :loadingExit="loadingExit"
                            :loadingJoin="loadingJoin"
                            :disableJoinClub="disableJoinClub"
                            :createChatLoading="createChatLoading"
                            @finishProject="finishProject($event)"
                            @createChat="createChat"
                            @openChat="openChat"
                            @joinGroup="joinGroup"
                            @leaveGroup="leaveGroup"
                            @goToEdit="goToEdit"
                            @shareToChat="shareToChat"
                            @deleteGroup="deleteGroup" />
                        <template v-else>
                            <template v-if="actions && actions.project_finish">
                                <a-button 
                                    v-show="isFounder" 
                                    v-if="is_project && requestData.finished"
                                    type="primary"
                                    ghost
                                    @click="finishProject(true)">
                                    {{$t('wgr.resume_project')}}
                                </a-button>
                                <a-button 
                                    v-show="isFounder"
                                    type="success"
                                    ghost
                                    v-if="is_project && !requestData.finished"
                                    @click="finishProject(false)">
                                    {{$t('wgr.finished_project')}}
                                </a-button>
                            </template>
                            <a-button
                                v-if="actions && actions.create_chat && !requestData.with_chat"
                                class="ml-2"
                                :loading="createChatLoading"
                                type="ui"
                                @click="createChat">
                                {{ $t('wgr.create_chat')  }}
                            </a-button>
                            <a-button
                                v-if="requestData.with_chat && requestData.linked_chat"
                                class="ml-2"
                                type="ui"
                                @click="openChat">
                                {{ $t('wgr.open_chat') }}
                            </a-button>
                            <a-button
                                v-if="actions && actions.edit"
                                type="ui"
                                class="ml-2"
                                ghost
                                shape="circle"
                                icon="fi-rr-edit"
                                flaticon
                                @click="goToEdit" />
                            <a-button
                                class="ml-2"
                                type="ui"
                                icon="fi-rr-share"
                                shape="circle"
                                ghost
                                flaticon
                                @click="shareToChat" />
                            <a-button 
                                v-if="actions && actions.delete"
                                class="ml-2"
                                type="ui"
                                ghost
                                icon="fi-rr-trash"
                                shape="circle"
                                flaticon
                                @click="deleteGroup()"/>
                            <a-button
                                v-if="!isStudent && !requestData.public_or_private"
                                type="primary"
                                ghost
                                class="ml-2"
                                :disabled="disableJoinClub"
                                :loading="loadingJoin"
                                @click="joinGroup">
                                {{ disableJoinClub ? $t("wgr.request_posted") : $t('wgr.join_group') }}
                            </a-button>
                            <a-button
                                v-if="isStudent && !isFounder"
                                @click="leaveGroup"
                                class="ml-2"
                                icon="fi-rr-exit"
                                ghost
                                flaticon
                                type="ui"
                                :loading="loadingExit">
                                {{ $t("wgr.exit") }}
                            </a-button>
                        </template>
                        <a-button 
                            @click="close2()"
                            type="ui"
                            shape="circle"
                            ghost
                            icon="fi-rr-cross"
                            flaticon
                            class="ml-2" />
                    </div>
                </div>

                <template v-if="requestData && requestData.id">
                    <template v-if="isMobile">
                        <div class="tab_wrapper">
                            <a-tabs 
                                v-model="active"
                                @change="changeTab">
                                <a-tab-pane
                                    v-for="tab in currentMenuRoutes"
                                    :key="tab.name">
                                    <template #tab>
                                        <div class="flex">
                                            <span>{{$t('wgr.'+tab.menuName)}}</span>
                                        </div>
                                    </template>
                                </a-tab-pane>
                            </a-tabs>
                        </div>
                    </template>
                    <template v-else>
                        <div class="page_switch">
                            <a-menu
                                mode="horizontal"
                                class="h-full"
                                v-model="activeMenu"
                                :defaultSelectedKeys="defaultActive"
                                @click="handleClickMenu">
                                <template v-for="item in currentMenuRoutes" >
                                    <template v-if="item.child && item.child.length">
                                        <a-sub-menu 
                                            v-if="item.child" 
                                            :key="item.name"
                                            @titleClick="titleClick($event, item)">
                                            <template slot="title">
                                                <span>{{$t('wgr.'+item.menuName)}}</span>
                                            </template>
                                            <a-menu-item 
                                                v-for="child in item.child" 
                                                :key="child.name">
                                                <span>{{$t('wgr.'+child.menuName)}}</span>
                                            </a-menu-item>
                                        </a-sub-menu>
                                    </template>
                                    <template v-else>
                                        <a-menu-item 
                                            class="h-full"
                                            :key="item.name">
                                            <div 
                                                class="h-full flex items-center"
                                                v-if="badgeShow">
                                                <a-badge
                                                    class="tab_badge"
                                                    :count="fileCount(item.name)" :overflow-count="99">
                                                    <span>{{$t('wgr.'+item.name)}}</span>
                                                </a-badge>
                                            </div>
                                            <span v-else>
                                                {{$t('wgr.'+item.menuName)}}
                                            </span>
                                        </a-menu-item>
                                    </template>
                                </template>
                            </a-menu>
                        </div>
                    </template>
                </template>

                <div 
                    class="group_content flex flex-col" 
                    :class="isMobile ? 'mobile_wrap' : 'full_wrap'">
                    <div 
                        class="group_cont_wrap flex flex-col flex-grow" 
                        :class="[active === 'kanban' && 'grp_kanbam_page', active === 'gant' && 'grp_gant_page', active === 'calendar' && 'grp_calendar_page']">
                        <a-row 
                            type="flex"
                            class="flex-grow" 
                            :class="isMobile && 'flex-col'"
                            :gutter="26">
                            <div 
                                v-show="loading"
                                class="great_loader">
                                <a-spin 
                                    color="primary" 
                                    size="large" />
                            </div>

                            <!-- COOOL  1-->
                            <!-- Вставка страниц из меню -->
                            <a-col 
                                class="flex_order flex flex-col flex-grow" 
                                :lg="showSidebar ? 18 : 24" 
                                :sm="24" 
                                :span="24" >
                                <template v-if="requestData">
                                    <template v-if="isStudent || isFounder">
                                        <Kanban
                                            :id="id"
                                            :actions="actions"
                                            :is_project="is_project"
                                            v-if="active === 'kanban'" />
                                        <Calendar
                                            :id="id"
                                            :isStudent="isStudent" 
                                            :isFounder="isFounder" 
                                            :is_project="is_project"
                                            v-if="active === 'calendar'" />
                                        <News 
                                            :id="id" 
                                            :actions="actions"
                                            v-if="active === 'news'"/>
                                        <Tasks 
                                            :isStudent="isStudent" 
                                            :isFounder="isFounder" 
                                            :actions="actions"
                                            :id="id" 
                                            v-if="active === 'tasks'"/>
                                        <Analytics 
                                            :id="id"
                                            :is_project="is_project"
                                            :requestData="requestData"
                                            v-if="active === 'analytics'"/>
                                        <Sprint
                                            :id="id"
                                            :actions="actions"
                                            :is_project="is_project"
                                            v-if="active === 'sprint'"/>
                                        <About 
                                            :id="id"
                                            :is_project="is_project"
                                            v-if="active === 'about'"
                                            :requestData="requestData"/>
                                        <Members
                                            v-if="active === 'about'"
                                            :id="id" 
                                            :isFounder="isFounder" 
                                            :isStudent="isStudent"
                                            :actions="actions"
                                            :updatePartisipants="updatePartisipants"
                                            :key="membersKey"/>
                                        <Files
                                            v-if="isStudent && active === 'group_files'" 
                                            :id="id" 
                                            :isFounder="isFounder" 
                                            :isStudent="isStudent" 
                                            :actions="actions"
                                            :key="groupFilesKey"/>
                                        <Files
                                            v-if="isStudent && active === 'chat_files'" 
                                            active="chat_files"
                                            :id="this.requestData.linked_chat_id" 
                                            :isFounder="isFounder" 
                                            :isStudent="isStudent"
                                            :actions="actions"
                                            :key="chatFilesKey"/>
                                        <!-- WARNING -->
                                        <Tasks 
                                            :isStudent="isStudent" 
                                            :isFounder="isFounder" 
                                            :id="id" 
                                            :actions="actions"
                                            taskType="interest"
                                            :key="interestKey"
                                            v-if="active === 'interest'"/>
                                        <!-- WARNING END -->

                                    </template>
                                    <template v-else>
                                        <a-result 
                                            v-if="!loading && !isStudent && requestData.public_or_private"
                                            status="403" 
                                            :title="$t('wgr.closed_group')" 
                                            :sub-title="$t('wgr.no_partisipants_group')">
                                            <template #extra>
                                                <a-button 
                                                    type="primary" 
                                                    icon="message"
                                                    @click="chatAuthor()">
                                                    {{ $t('wgr.author_chat') }}
                                                </a-button>
                                            </template>
                                        </a-result>
                                        <template v-if="!loading && !requestData.public_or_private">
                                            <a-result 
                                                v-if="!isStudent || !isFounder"
                                                status="404" 
                                                :title="$t('wgr.group_info_hide')" 
                                                :sub-title="$t('wgr.group_info_hide_desc')">
                                                <template #extra>
                                                    <a-button 
                                                        type="primary"
                                                        size="large"
                                                        ghost
                                                        :disabled="disableJoinClub"
                                                        :loading="loadingJoin"
                                                        @click="joinGroup">
                                                        {{ disableJoinClub ? $t("wgr.request_posted") : $t('wgr.join_group') }}
                                                    </a-button>
                                                </template>
                                            </a-result>
                                        </template>
                                    </template>
                                </template>
                            </a-col>

                            <a-col
                                v-if="showSidebar"
                                class="flex_basis" 
                                :lg="6" 
                                :sm="24" 
                                :span="24">
                                <!-- Завершить проект -->
                                <a-alert 
                                    :message="$t('wgr.project_finished')" 
                                    class="mb-2"
                                    show-icon
                                    v-if="requestData.finished" 
                                    type="success" />

                                <About :requestData="requestData"/>
                                  
                                <!-- Участники -->
                                <Members 
                                    v-if="isStudent" 
                                    :id="id" 
                                    :isFounder="isFounder" 
                                    :isStudent="isStudent"
                                    :actions="actions"
                                    :updatePartisipants="updatePartisipants"
                                    :key="membersKey"/>
                            </a-col>
                        </a-row>
                    </div>
                </div>
            </template>
        </template>
    </DrawerTemplate>
</template>

<script>
import { mapActions,  mapMutations, mapState } from 'vuex'
import DrawerTemplate from '../widgets/DrawerTemplate.vue'

import eventBus from "@/utils/eventBus"

import News from  './PagesSwitch/News.vue'
import Tasks from './PagesSwitch/Tasks.vue'
import Kanban from './PagesSwitch/Kanban.vue'
import Calendar from './PagesSwitch/Calendar.vue'
import Analytics from './PagesSwitch/Analytics.vue'
import Sprint from './PagesSwitch/Sprint.vue'

import About from './modules/About'
import Members from './modules/Members'
import Files from './modules/Files.vue'

export default {
    name: "GroupsAndProjectsMainPage",
    components: {
        DrawerTemplate,
        News,
        Tasks,
        Kanban,
        About,
        Members,
        Calendar,
        Analytics,
        Sprint,
        Files
    },
    data(){
        return{
            menuProject:  [] ,
            visible: false,
            active: 'news',
            activeMenu: ['news'],
            defaultActive: ['news'],

            loading: false,

            loadingJoin: false,
            loadingExit: false,
            createChatLoading: false,
            requestData: { social_links: [] },

            dialogJoin: false,
            disableJoinClub: false,

            roles: [],

            isFounder: false,
            isStudent: false,

            mainKey: this.getKey(),
            eventKey: this.getKey(),
            galeryKey: this.getKey(),
            groupFilesKey: this.getKey(),
            chatFilesKey: this.getKey(),
            membersKey: this.getKey(),
            interestKey: this.getKey(),
            groupFileCount: 0,
            chatFileCount: 0,
            actions: null
        }
    },
    watch: {
        '$route.query.viewGroup'() {
            if(this.$route.query.viewGroup)
                this.startView()
        },
        '$route.query.viewProject'() {
            if(this.$route.query.viewProject)
                this.startView()
        },
        '$route.query'(val){
            if(!val.viewProject && !val.viewGroup){
                this.visible = false
            }
        }
    },

    computed:{
        ...mapState({
            windowWidth: state => state.windowWidth
        }),
        workgroupLogoPath() {
            return this.requestData?.workgroup_logo?.path || null
        },
        currentMenuRoutes() {
            let tabs = this.requestData?.tabs || []

            tabs.forEach((item, index) => {
                if(item.onlyMobile && !this.isMobile)
                    tabs.splice(index, 1)
                if(item.onlyDesktop && this.isMobile)
                    tabs.splice(index, 1)
            })

            if(this.id === '59449eea-4536-11ed-bca7-4216f3de51df') {
                tabs = tabs.concat({
                    name: 'interest',
                    menuName: "interest",
                    icon: "interest"
                })
            }

            return tabs
        },
        id() {
            const query =  Object.assign({}, this.$route.query)
            if(query.viewGroup) return this.$route.query.viewGroup
            if(query.viewProject) return this.$route.query.viewProject
            return 0
        },
        is_project() {
            return this.requestData.is_project
        },
        widthDrawer() {
            if(this.windowWidth > 1200)
                return this.windowWidth - 250
            else
                return '100%'
        },
        showSidebar() {
            if(this.active === 'kanban' || this.active === 'gant' || this.active === 'tasks' || this.isMobile || this.active === 'calendar')
                return false
            else
                return true
        },
        isMobile() {
            return this.$store.state.isMobile
        },
        burgerWidget() {
            if(this.isMobile)
                return () => import('./modules/BurgerMobile.vue' )
            return false
        },
    },
    methods:{
        ...mapActions({
            getInfos: "workgroups/getInfo",
            getRolesS: "workgroups/getRoles",
            joinGroupS: "workgroups/joinGroup",
            leaveGroupS: "workgroups/leaveGroup",
            finishedDate: "workgroups/finishedDate",
            addNewChat: 'workgroups/addNewChat'

        }),
        ...mapMutations({
            setLoading: "workgroups/setLoading"
        }),
        afterVisibleChange(vis) {
            if(!vis) {
                this.close()
            }
        },
        badgeShow() {
            return ((isStudent || isFounder) && item.name === 'group_files' && groupFileCount) || ((isStudent || isFounder) && item.name === 'chat_files' && chatFileCount)
        },
        fileCount(name) {
            if(name==='group_files') {
                return this.groupFileCount
            } else if (name==='chat_files') {
                return this.chatFileCount
            } else {
                return 0
            }
        },
        shareToChat(){
            let is_project =  this.requestData.is_project
            this.$store.commit('share/SET_SHARE_PARAMS', {
                model: 'workgroups.WorkGroupModel',
                shareId: this.requestData.id,
                object: this.requestData,
                shareUrl: `${window.location.origin}/?${is_project ? 'viewProject': 'viewGroup'}=${ this.requestData.id}`,
                shareTitle: `${is_project ? 'Проект': 'Группа'} - ${ this.requestData.name}`
            })
        
        },
        deleteGroup() {
            this.$confirm({
                title: this.$t('wgr.warning'),
                content: this.$t('wgr.delete_confirm_text', { type: this.is_project ? this.$t('wgr.project_label') :  this.$t('wgr.group_label') }),
                zIndex: 2200,
                cancelText: this.$t('wgr.close'),
                okText: this.$t('wgr.delete'),
                okType: 'danger',
                onOk: () => {
                    return new Promise((resolve, reject) => {
                        this.$http.post(`/work_groups/workgroups/${this.requestData.id}/delete/`).
                            then(() => {
                                this.$message.success(`${this.is_project ? this.$t('wgr.project_delete') : this.$t('wgr.group_delete')}`)
                                if(this.is_project) {
                                    eventBus.$emit('update_list_project')
                                    eventBus.$emit('project_deleted', this.requestData.id)
                                } else {
                                    eventBus.$emit('update_list_group')
                                } 
                                this.close2()
                                resolve()
                            })
                            .catch((e) => {
                                console.log(e)
                                reject()
                            })
                    })
                },
                onCancel() {}
            })
        },
        updatePartisipants(users) {
            this.requestData.workgroup_members = users
        },
        chatAuthor() {
            this.visible = false
            this.$router.push({name: 'chat', query: {user: this.requestData.founder.id}})  
        },
        openChat(){
            this.visible = false
            this.$router.push({name: 'chat', query: {chat_id: this.requestData.linked_chat}})  
        },
        async createChat(){
            try{ 
                this.createChatLoading = true
                const res = await this.addNewChat({data: {with_chat: true}, id: this.id})
                if(res?.chat_uid) {
                    const self = this
                    this.requestData.with_chat = true
                    this.requestData.linked_chat = res.chat_uid
                    this.$success({
                        title: 'Чат успешно создан!',
                        okText: 'Открыть чат',
                        okType: 'success',
                        closable: true,
                        maskClosable: true,
                        cancelText: 'Закрыть',
                        onOk() {
                            self.close()
                            self.visible = false
                            self.$router.push({name: 'chat', query: {chat_id: res.chat_uid}})  
                        },
                        onCancel(){}
                    })
                }
            } 
            catch(e){
                this.$message.error(this.$t('wgr.error') + e)
            }
            finally{
                this.createChatLoading = false
            }
        },
        getKey() {
            return  Math.floor(Math.random() * 99999)
        },
        close2() {
            eventBus.$emit('update_workgroup_data')
            const query = Object.assign({}, this.$route.query)

            if(query.tab)
                delete query.tab
            if(query.filters)
                delete query.filters

            delete query['viewGroup']
            delete query['viewProject']

            this.$router.replace({query})
            this.isFounder = false
            this.isStudent = false
            this.active = this.isMobile ? 'about' : 'news'
            this.activeMenu = [this.isMobile ? 'about' : 'news']
            this.requestData = { social_links: [] }
            this.visible = false
        },
        close(){
            const query = Object.assign({}, this.$route.query)

            if(query.tab)
                delete query.tab
            if(query.filters)
                delete query.filters

            delete query['viewGroup']
            delete query['viewProject']

            this.$router.replace({query})
            this.groupFileCount = 0
            this.chatFileCount = 0
            this.isFounder = false
            this.isStudent = false
            this.active = this.isMobile ? 'about' : 'news'
            this.activeMenu = [this.isMobile ? 'about' : 'news']
            this.requestData = { social_links: [] }
        },

        async getTabAttachments() {
            try {
                if(this.isStudent || this.isFounder) {
                    const groupAttachmentsCount = await this.$http(
                        `attachments/${ this.id }/aggregate/`)
                    this.groupFileCount = groupAttachmentsCount.data.files
                    if(this.requestData.with_chat) {
                        const chatAttachmentsCount = await this.$http(
                            `attachments/${ this.requestData.linked_chat_id }/aggregate/`)
                        this.chatFileCount = chatAttachmentsCount.data.files
                    }                          
                }
            } catch(e) {

            }
        },

        async startView(){
            try{
                this.loading = true
                this.setLoading(true)
                if (this.id !== undefined) {
                    // Получени основной ифномрации о группе
                    this.visible = true
                    const res = await this.getInfos(this.id)
                    this.requestData = res
                    await this.getRoles()
                    await this.getActions()
                    this.getTabAttachments()
                    const query = JSON.parse(JSON.stringify(this.$route.query))
                    if(query.tab) {
                        this.active = query.tab
                        this.activeMenu = [query.tab]
                    }
                }
            }
            catch(error){
                if(error && error.detail) {
                    if(error.detail === 'Не найдено.' || error.detail === 'Страница не найдена.' || error.detail === 'У вас недостаточно прав для выполнения данного действия.') {
                        this.$message.warning('Группа/Проект не найден или удален')
                        this.close()
                    } else {
                        this.$message.error('Ошибка')
                    }
                } else {
                    this.$message.error('Ошибка')
                }
                console.log(error)
            }
            finally{
                this.loading = false
                this.setLoading(false)
            }


        },
        setRouterTab(val) {
            let query = JSON.parse(JSON.stringify(this.$route.query))
            query.tab = val
            this.$router.push({query}).catch(e=>{})
        },
        // Меню и переход на нужный таб
        handleClickMenu(e){
            this.active = e.key
            this.setRouterTab(e.key)
        },
        titleClick(e, item) {
            this.activeMenu = [item.mainPage]
            this.active = item.mainPage
            this.setRouterTab(item.mainPage)
        },
        // Заершить продолжить проект
        finishProject(val){
            try{
                if(val){
                    this.finishedDate({id: this.id, date: null})
                    this.requestData.finished = false
                    this.requestData.finishedDate = null
                } else {
                    this.finishedDate({id: this.id, date: this.$moment()})
                    this.requestData.finished = true
                    this.requestData.finishedDate = this.$moment()

                }
            }
            catch(error){
                this.$message.error(this.$t('wgr.error') + error)
            }

        },



        // Отправть запрос на вступление

        async  joinGroup(){
            try{
                this.loadingjoin= true
                await   this.joinGroupS({id: this.id, member_visible: true})
                this.$message.success(this.$t('wgr.you_partisipants'))
                this.isStudent = true;
                this.membersKey += 1
                this.loadingjoin= false
            }
            catch(error){
                this.$message.error(this.$t('wgr.error') + error)
            }

        },

        // Выйти из группы
        async leaveGroup(){
            try{
                this.loadingExit = true
                await this.leaveGroupS(this.id)
                this.membersKey += 1
                this.$message.warning(this.$t('wgr.you_not_member_group'))
                this.isStudent = false;
                this.loadingExit = false
            }
            catch(error){
                this.$message.error(this.$t('wgr.error') + error)
            }
        },

        // Получение экшенов
        async getActions() {
            try {
                const { data } = await this.$http.get(`/work_groups/workgroups/${this.id}/action_info/`)
                if(data?.actions) {
                    this.actions = data.actions
                }
            } catch(e) {
                console.log(e)
            }
        },

        // Получение ролей
        async getRoles() {
            try{
                const res = await  this.getRolesS(this.id)
                if (res[0].id) {
                    this.roles = res;
                    res.forEach((item) => {
                        if (
                            item.membership_role.code === "FOUNDER" ||
                        item.membership_role.code === "MODERATOR"
                        ) {

                            this.isFounder = true;
                            this.isStudent = true;
                        } else if (
                            item.membership_role.code === "MEMBER"
                        ) {
                            this.isStudent = true;
                        }
                    });
                }
            }
            catch(error){
                this.$message.error(this.$t('wgr.error') + error)
            }
        },

        // Кнопка редактировать группу
        goToEdit() {
            const query = this.is_project ? {updateProject: this.id} : {updateGroup: this.id}
            this.$router.replace({
                query
            })
            this.visible = false
        },

        changeTab(tabName) {
            this.active = tabName
            this.activeMenu = [tabName]
            this.setRouterTab(tabName)
        },
    },
    async created() {

        if(this.isMobile) {
            this.defaultActive = ['about']
            this.activeMenu = ['about']
            this.active = 'about'
        }

        const query = this.$route.query
        if(query.viewProject || query.viewGroup)
            this.startView()

    },
}
</script>

<style>
@media  (max-width: 800px) {
    .flex_basis {
        flex-basis: auto;
    }
    .flex_order {
        order: 1;
    }
    .mobile_dummy {
        padding-top: 30px;
    }
}

</style>

<style lang="scss">
.workgroup_drawer_collapse {
    margin: 0 -15px;
    .ant-collapse-header {
        font-weight: 500;
    }
    .ant-collapse-content-box {
        padding-top: 5px;
        padding: 15px;
    }
}

.group_drawer{
    .about_card_mobile{
        border: none;
        .ant-card-head {
            padding: 0;
            border: none;
        }
        .ant-card-body {
            padding: 0;
        }
    }
    .group_content{
        overflow-y: auto;
        &.mobile_wrap{
            height: calc(100% - 96px);
        }
        &.full_wrap{
            height: calc(100% - 104px);
        }
        .group_cont_wrap{
            padding: 30px 30px 20px 30px;
            &.grp_calendar_page,
            &.grp_kanbam_page,
            &.grp_gant_page{
                height: 100%;
                .ant-row-flex{
                    height: 100%;
                }
                .ant-col{
                    height: 100%;
                }
            }
            &.grp_calendar_page,
            &.grp_kanbam_page{
                padding: 0px;
            }
        }
    }
    .page_switch{
        border-bottom: 1px solid var(--border2);
        height: 48px;
        background: rgba(255, 255, 255, 0.85);
        padding: 0 30px;
        .ant-menu{
            border: 0px;
            background: transparent;
        }
    }
    .group_header{
        padding: 10px 30px;
        border-bottom: 1px solid var(--border2);
        background: rgba(255, 255, 255, 0.85);
    }
    .ant-drawer-header{
        display: none;
    }
    .ant-drawer-wrapper-body,
    .ant-drawer-content{
        overflow: hidden;
    }
    .ant-drawer-body{
        height: 100%;
        padding: 0px;
        overflow-y: hidden;
        .drawer_body{
            height: 100%;
        }
    }
    .group_header{
        .ant-avatar{
            border: 1px solid var(--border2);
        }
    }
}
.group_drawer_mobile {
    .group_content{
        .group_cont_wrap{
            padding: 15px;
        }
    }
    .page_switch {
        padding: 0 15px;
    }
    .tab_wrapper {
        .ant-tabs-bar {
            margin-bottom: 0;
        }
        .ant-tabs-tab {
            padding: 10px 16px;
        }

    }
    .group_header {
        padding: 10px 15px;
    }
    .ant-result-image {
        position: relative;
        height: 150px;
        width: 150px;
        svg {
            position: absolute;
            top: 0;
            left: 0;
            transform: scale(0.5) translate(-50%, -50%);
        }
    }
}

.tab_badge {
    .ant-badge-count {
        right: -15px;
    }
}
</style>
